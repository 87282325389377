import React from "react"
import { Link } from 'gatsby'

import Layout from '../components/Layout'
import SEO from "../components/SEO"

if (typeof window !== "undefined") {
    // eslint-disable-next-line global-require
    require("smooth-scroll")('a[href*="#"]')
}

const About = () => {
    return (
        <Layout>
            <SEO title={"About"} />
            <div className="rbs-section">
                <div className="rbs-box-a">
                    <div className="content">
                        <h1 className="title-xl mb-16">
                            About
                        </h1>
                        <p className="body-l mb-8 md:w-3/4">
                            Remote Brand Sprint is a sister company of <a href="https://taru.io" className="link">TARU Design Lab</a>. We work as a remote global network of designers, strategists, and digital product experts. This allows us to rapidly adapt our project teams for various contexts and challenges we are facing.
                        </p>
                        <p className="body-l mb-8 md:w-3/4">
                        <a href="https://remotebrandsprint.substack.com" target="_blank" rel="noreferrer" className="link">Subscribe to Newsletter</a> for updates and a look behind the scenes. No more than one email per month.
                        </p>
                        <Link to="/contact" className="body-l link-arrow">Contact Us</Link>
                    </div>
                </div>
                <div className="rbs-bg bg-07-a"></div>
            </div>

        </Layout>
    )
}

export default About